











































import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'uOttawa1321Prelab3Q5',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        studentAnswer: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      options: [
        {text: 'To make sure the chemicals react', value: 'react'},
        {text: 'Mass transfer between two immiscible phases is required', value: 'transfer'},
        {text: 'To facilitate the evaporation of solvents', value: 'evaporation'},
        {text: 'To make sure all the chemicals are fully dissolved', value: 'dissolved'},
      ],
      optionsFr: [
        {text: 'Pour être certain que les produits chimiques réagissent', value: 'reactFr'},
        {
          text: 'Un transfert de masse entre deux phrases immiscibles est nécessaire',
          value: 'transferFr',
        },
        {text: "Pour faciliter l'évaporation des solvants", value: 'evaporationFr'},
        {
          text: 'Pour être certain que les produits chimiques sont complètement dissous',
          value: 'dissolvedFr',
        },
      ],
    };
  },
};
